import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { Nullable } from '@local/webviz/dist/types/xyz';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useSceneObjectSelectionManager } from 'src/hooks/useSceneObjectSelectionManager';
import { selectCurrentProjectAnalyticalModel } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjectIds } from 'src/store/visualization/selectors';

import { ObjectListItemControl } from '../ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './AggregateTreePanel.styles';

export function SkeletonArtifactsPanelContents() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.content}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

function nonNullable<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function AggregateTreePanel() {
    const { classes } = useStyles();
    const [currentVolumes, setCurrentVolumes] =
        useState<Nullable<{ volumes: GtmEvoOutputObject[] }>>(null);
    const [currentSelectableObjectList, setCurrentSelectableObjectList] = useState<
        GtmEvoOutputObject[]
    >([]);
    const [currentComposite, setCurrentComposite] =
        useState<Nullable<{ composite: GtmEvoOutputObject }>>(null);

    const { onObjectControlSelection } = useSceneObjectSelectionManager();
    const selectedObjectIds = useAppSelector(selectedSceneObjectIds);
    const currentAnalyticalModel = useAppSelector(selectCurrentProjectAnalyticalModel);

    useEffect(() => {
        if (currentAnalyticalModel) {
            const { volumes, composite_model: compositeModel } = currentAnalyticalModel;
            const concatenatedModels = volumes
                ? [...volumes, compositeModel].filter(nonNullable)
                : [];
            setCurrentVolumes(volumes ? { volumes } : null);
            setCurrentComposite(compositeModel ? { composite: compositeModel } : null);
            setCurrentSelectableObjectList(concatenatedModels);
        } else {
            setCurrentVolumes(null);
            setCurrentComposite(null);
            setCurrentSelectableObjectList([]);
        }
    }, [currentAnalyticalModel]);

    return (
        <Grid item xs className={classnames(classes.contentsContainer, classes.content)}>
            {currentComposite?.composite && currentSelectableObjectList && (
                <Grid
                    key={currentComposite.composite.id}
                    onClick={(event) =>
                        onObjectControlSelection(
                            currentSelectableObjectList,
                            0,
                            currentComposite.composite.id,
                            event,
                        )
                    }
                >
                    <ObjectListItemControl
                        objectListItem={currentComposite.composite}
                        selectedObjectIds={selectedObjectIds}
                        addToSceneOnMount
                    />
                </Grid>
            )}
            {currentVolumes?.volumes &&
                currentSelectableObjectList &&
                currentVolumes.volumes.map((child: GtmEvoOutputObject, index: number) => (
                    <Grid
                        key={child.id}
                        onClick={(event) =>
                            onObjectControlSelection(
                                currentSelectableObjectList,
                                index,
                                child.id,
                                event,
                            )
                        }
                    >
                        <ObjectListItemControl
                            objectListItem={child}
                            selectedObjectIds={selectedObjectIds}
                        />
                    </Grid>
                ))}
        </Grid>
    );
}
