import { TabGroup } from '@local/central-portal-core/src/central/components/Visualization/ArtifactsPanel/TabGroup';
import { DragTab } from '@local/web-design-system/dist/components/DragTab';
import { IconLabel } from '@local/web-design-system/dist/components/IconLabel';
import { GeologicalModelIcon } from '@local/web-design-system/dist/icons/Artifacts/GeologicalModelIcon';
import { GMSubFaultedIcon } from '@local/web-design-system/dist/icons/Artifacts/GMSubFaultedIcon';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Split from 'react-split';

import { useListFilesQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { DownloadFileResponse } from 'src/apiClients/file/GENERATED_fileClientEndpoints';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { addOrUpdateSceneObject } from 'src/store/visualization/visualizationSlice';
import { PROJECT_EXTENSION } from 'src/strings';
import { fileNameExtensionRemover } from 'src/utils';

import { BoundingBoxDialog } from '../../BoundingBoxDialog';
import {
    BASE_MODEL,
    MIN_PANEL_HEIGHT_PX,
    MY_SCENE_PANEL_HEIGHT_PERCENTAGE,
    OBJECTS_PANEL_HEIGHT_PERCENTAGE,
    PROJECT_TREE,
    AGGREGATE,
} from './ArtifactsPanel.constants';
import { useStyles } from './ArtifactsPanel.styles';
import { BoundariesPanel } from './BoundariesPanel/BoundariesPanel';
import { AggregateTreePanel } from './ProjectTree/Aggregate/AggregateTreePanel';
import { ProjectTreePanel } from './ProjectTree/ProjectTreePanel';

const baseModelOption = {
    key: BASE_MODEL,
    label: () => <IconLabel label={BASE_MODEL} Icon={GeologicalModelIcon} />,
};

export interface CurrentProject {
    name: string;
    fileId: string;
    versionId: string;
}

interface ProjectListItem {
    projectName: string;
    component: ReactElement<any>;
}

function ProjectTree({
    projectOptions,
    onSelectProject,
}: {
    projectOptions: ProjectListItem[];
    onSelectProject: (fileName: string) => void;
}) {
    const { projectName } = useParams();
    const onChange = (event: SelectChangeEvent) => {
        onSelectProject(event.target.value as string);
    };
    return (
        <>
            <Grid>
                <Select
                    value={projectName ?? BASE_MODEL}
                    onChange={onChange}
                    disabled={false}
                    fullWidth
                    title=""
                >
                    <MenuItem value={BASE_MODEL}>{baseModelOption.label()}</MenuItem>
                    {projectOptions.map((projectOption) => (
                        <MenuItem value={projectOption.projectName}>
                            {projectOption.component}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <ProjectTreePanel />
        </>
    );
}

export function ArtifactsPanel() {
    const { classes } = useStyles();
    const gutterRef = useRef(null);
    const [projectOptions, setProjectOptions] = useState<ProjectListItem[]>([]);
    const params = useParams();
    const { clearGtmObjects } = useSceneObjectDataManager();
    const dispatch = useDispatch();
    const selectedObjects = useAppSelector(selectedSceneObjects);
    const [showSetBoundaryDialog, setShowSetBoundaryDialog] = useState(false);
    const navigate = useNavigate();
    const { data: listFilesData } = useListFilesQuery({
        organisationId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });

    useEffect(() => {
        if (listFilesData) {
            const { files } = listFilesData || {};
            const gtmFiles = files?.filter((file) => file.name.endsWith(`.${PROJECT_EXTENSION}`));
            setProjectOptions(
                gtmFiles?.map((file) => ({
                    projectName: file.name,
                    component: (
                        <IconLabel
                            label={fileNameExtensionRemover(file.name)}
                            Icon={GMSubFaultedIcon}
                        />
                    ),
                })) || [],
            );
        }
    }, [listFilesData]);

    async function handleSelectProject(fileName: string) {
        const projectIsAlreadySelected =
            params.projectName === fileName || (!params.projectName && fileName === BASE_MODEL);
        if (projectIsAlreadySelected) return;

        // We have to remove all objects from the ObjectRevisionContext so that
        // when we go to re-load those objects, we fetch them again.
        clearGtmObjects();

        navigate(
            `/${getOrgUuidFromParams(params)}/workspace/${getSelectedWorkspaceFromParams(
                params,
            )}/project/${fileName}`,
        );
    }

    const aggregate = <AggregateTreePanel />;

    const projectTree = (
        <ProjectTree
            projectOptions={projectOptions}
            onSelectProject={(fileName: string) => {
                handleSelectProject(fileName);
            }}
        />
    );

    const handleBoundaryCreated = (fileData: DownloadFileResponse) => {
        const newProjectOptions = [
            ...projectOptions,
            {
                projectName: fileData.name,
                component: (
                    <IconLabel
                        label={fileNameExtensionRemover(fileData.name)}
                        Icon={GMSubFaultedIcon}
                    />
                ),
            },
        ];
        setProjectOptions(newProjectOptions);

        handleSelectProject(fileData.name);
    };

    return useMemo(
        () => (
            <Grid
                container
                className={classes.root}
                direction="column"
                wrap="nowrap"
                alignItems="stretch"
            >
                <Split
                    sizes={[OBJECTS_PANEL_HEIGHT_PERCENTAGE, MY_SCENE_PANEL_HEIGHT_PERCENTAGE]}
                    minSize={MIN_PANEL_HEIGHT_PX}
                    direction="vertical"
                    cursor="row-resize"
                    className={classes.splitContainer}
                    gutter={() => gutterRef.current}
                >
                    <TabGroup
                        groups={[
                            {
                                label: PROJECT_TREE,
                                panel: projectTree,
                            },
                            {
                                label: AGGREGATE,
                                panel: aggregate,
                            },
                        ]}
                        className={classes.objectsPanelContainer}
                        initialTab={0}
                        callback={() => {
                            Object.entries(selectedObjects).forEach(([id]) => {
                                dispatch(addOrUpdateSceneObject([id, { isSelected: false }]));
                            });
                        }}
                    />

                    <Grid item className={classes.lowerPanelContainer}>
                        <Grid item container ref={gutterRef}>
                            <DragTab className={classes.gutter} direction="row" />
                        </Grid>
                        {!showSetBoundaryDialog && (
                            <BoundariesPanel onClick={() => setShowSetBoundaryDialog(true)} />
                        )}
                        {showSetBoundaryDialog && (
                            <BoundingBoxDialog
                                existingProjectNames={projectOptions.map(
                                    (po) => po.projectName as string,
                                )}
                                onClose={() => setShowSetBoundaryDialog(false)}
                                onBoundaryCreated={handleBoundaryCreated}
                            />
                        )}
                    </Grid>
                </Split>
            </Grid>
        ),
        [
            gutterRef,
            projectOptions,
            showSetBoundaryDialog,
            params.projectName,
            projectTree,
            aggregate,
        ],
    );
}
