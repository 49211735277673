import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import MinusIcon from '@local/web-design-system/dist/icons/Actions/MinusIcon';
import PlusIcon from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import WarningIcon from '@local/web-design-system/dist/icons/Alerts/WarningIcon';
import { DefaultObjectIcon } from '@local/web-design-system/dist/icons/Artifacts/DefaultObjectIcon';
import { MeshIcon } from '@local/web-design-system/dist/icons/Artifacts/MeshIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { useDefectsLoadingManager } from 'src/hooks/defects/useDefectsLoadingManager';
import { selectDefectsById } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { fileNameExtensionRemover } from 'src/utils';
import { gtmColorToRGBArray } from 'src/utils/typeTransformations';

import { useSceneObjectDataManager } from '../../../../../hooks/useSceneObjectDataManager';
import { ObjectActionMenu } from './ObjectActionMenu';
import { useStyles } from './ObjectListItemControl.styles';
import { ObjectListItemControlProps } from './ObjectListItemControl.types';
import { WireframeControl } from './PropertiesToggleControl';

const APPROXIMATE_MAX_LOAD_TIME = 15000;

export enum Schemas {
    TriangleMeshSchema = 'triangle-mesh.schema.json',
}

export const SchemaIcon = (schema: string) => {
    const iconsDictionary = {
        [Schemas.TriangleMeshSchema]: MeshIcon,
    };

    const gooseType = extractSchema(schema);
    if (gooseType in iconsDictionary) {
        return iconsDictionary[gooseType as keyof typeof iconsDictionary];
    }

    return DefaultObjectIcon;
};

const extractSchema = (fullSchema: string | null) => {
    if (!fullSchema) {
        return '';
    }

    return fullSchema.substring(fullSchema.lastIndexOf('/') + 1).toLowerCase();
};

export function ObjectListItemControl({
    objectListItem,
    selectedObjectIds,
    addToSceneOnMount,
}: ObjectListItemControlProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace('object-list-item');
    const { getZoomToViewTool } = useBaseXyz();
    const { runAllDetectors } = useDefectsLoadingManager();

    const { id: objectId, version_id: versionId, name, schema, color } = objectListItem;

    const defectData = useAppSelector(selectDefectsById(objectId));

    const itemControlRef = useRef<HTMLDivElement>(null);

    const {
        loadGtmObject,
        removeGtmObject,
        isObjectOnPlotByObjectId,
        useViewListener,
        updateObjectColor,
        // loadGtmTilesetObject,
    } = useSceneObjectDataManager();
    const objectData = useAppSelector(sceneObjectById(objectId));

    const { isViewLoading, isViewError } = useViewListener(objectId);

    const isObjectOnPlot = isObjectOnPlotByObjectId(objectId);

    const handleAddObject = async () => {
        await loadGtmObject(objectId, versionId, name);
        if (color) {
            updateObjectColor(objectId, gtmColorToRGBArray(color));
        }
    };
    const initialLoad = useCallback(async () => {
        if (objectId && versionId && addToSceneOnMount) {
            handleAddObject();
        }
        if (objectId && versionId) {
            runAllDetectors(objectListItem);
        }
    }, [objectId, versionId, color, name]);

    useEffect(() => {
        initialLoad();
    }, [initialLoad]);

    const handleRemoveObject = () => {
        removeGtmObject(objectId);
    };

    function handleZoomToView() {
        getZoomToViewTool().zoomToView(objectId);
    }

    const Icon = SchemaIcon(schema);

    const showDefectWarning = defectData && defectData?.total > 0;
    const defectCount = defectData?.total ?? 0;

    return (
        <Grid
            item
            container
            className={classnames(classes.root, { [classes.errorContainer]: false })}
        >
            <Grid
                className={classnames(classes.background, {
                    [classes.backgroundPending]: objectData?.isLoading || isViewLoading,
                    [classes.backgroundClear]: objectData?.isSuccess || isViewError,
                    [classes.backgroundSelectedForLoading]: selectedObjectIds?.includes(objectId),
                })}
                ref={itemControlRef}
            />
            <Grid
                item
                container
                className={classes.objectListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.mainContainerOutlined]: false,
                    })}
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="stretch"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        {...applyTrace(name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon />
                        </Grid>
                        <Typography variant="body2" color="inherit" className={classes.name}>
                            {fileNameExtensionRemover(name)}
                        </Typography>
                    </Grid>
                    {(objectData?.isLoading || isViewLoading) && (
                        <Grid className={classes.progressContainer}>
                            <LinearProgressFaked
                                isLoading={objectData?.isLoading || isViewLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {!isObjectOnPlot && (
                        <Tooltip title="Add into scene" placement="top" arrow enterDelay={0}>
                            <IconButton
                                onClick={handleAddObject}
                                className={classnames(classes.loadIconButton)}
                            >
                                <PlusIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isObjectOnPlot && (
                        <Grid item container xs alignItems="stretch" wrap="nowrap">
                            <WireframeControl viewId={objectId} />
                            {showDefectWarning && (
                                <Tooltip
                                    title={`${defectCount} defect(s) detected`}
                                    placement="top"
                                    arrow
                                    enterDelay={0}
                                >
                                    <Grid className={classnames(classes.warningIconStyle)}>
                                        <WarningIcon color="warning" fontSize="inherit" />
                                    </Grid>
                                </Tooltip>
                            )}
                            <Tooltip title="Remove from scene" placement="top" arrow enterDelay={0}>
                                <IconButton
                                    onClick={handleRemoveObject}
                                    className={classnames(classes.removeIconButton)}
                                >
                                    <MinusIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            <ObjectActionMenu
                                onRemoveItem={handleRemoveObject}
                                onZoomToView={handleZoomToView}
                                zoomToViewActive
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
