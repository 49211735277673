import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';

export function NotFoundPage({ title, message }: { title: string; message: string }) {
    return (
        <div
            style={{
                padding: 16,
                paddingTop: 0,
                height: '90vh',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            }}
        >
            <div
                style={{
                    width: '70%',
                    margin: 'auto',
                }}
            >
                <EmptyState image={<NotFoundSvg />} title={title} message={message} />
            </div>
        </div>
    );
}
