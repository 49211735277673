// We will want to autogenerate these types and the API once we are in Evo compute.
// For now, let's expose a few items that we need to interact with the API for a
// prototype demo using our monolithic lambda.

export enum GtmMeshGetMeshDataAction {
    GetMeshData = 'GetMeshData',
}

export enum GtmMeshDetectorAction {
    DetectDegenerateTris = 'DetectDegenerateTris',
    DetectDuplicatePoints = 'DetectDuplicatePoints',
    DetectDuplicateTris = 'DetectDuplicateTris',
    DetectFins = 'DetectFins',
    DetectHoles = 'DetectHoles',
    DetectInconsistentlyOrientedTris = 'DetectInconsistentlyOrientedTris',
    DetectNonManifoldEdges = 'DetectNonManifoldEdges',
    DetectNonManifoldVertices = 'DetectNonManifoldVertices',
    // TODO: Update name of `DetectRedundantSurfaces` detector (GEOM-306)
    DetectNonPartitioningSurfaces = 'DetectRedundantSurfaces',
    DetectSelfIntersections = 'DetectSelfIntersections',
}

export enum GtmMeshTransformationAction {
    AddToAggregateGeom = 'AddToAggregateGeom',
    Clip = 'Clip',
    CreateAnalyticalBoundary = 'CreateAnalyticalBoundary',
    FillHoles = 'FillHoles',
    InitAggregateGeom = 'InitAggregateGeom',
    ParameterizeVolumes = 'ParameterizeVolumes',
    RemoveDegenerateTris = 'RemoveDegenerateTris',
    RemoveTris = 'RemoveTris',
    RemoveUnusedPoints = 'RemoveUnusedPoints',
    SeparateVolumes = 'SeparateVolumes',
    Translate = 'Translate',
    UploadMeshData = 'UploadMeshData',
}

export type GtmMeshRequestAction =
    | GtmMeshGetMeshDataAction
    | GtmMeshDetectorAction
    | GtmMeshTransformationAction;

// Distinguish the role/provenance of the object that we are dealing with.
export enum GtmObjectType {
    Undefined = 'Undefined',
    AggregateGeometry = 'AggregateGeometry',
    VolumeShell = 'VolumeShell',
}

type EmptyObject = Record<string, never>;

type GtmMeshGetMeshDataParams = EmptyObject;

interface GtmMeshDetectHolesParams {
    holeSizeRatioTolerance: number;
}

interface GtmMeshDetectDegenerateTriangleParams {
    needleThresholdRatio: number;
    capMinAngleRad: number;
}

interface GtmMeshDetectSelfIntersectionsParams {
    tolerance: number;
}

export type GtmMeshDetectorParams =
    | GtmMeshDetectHolesParams
    | GtmMeshDetectDegenerateTriangleParams
    | GtmMeshDetectSelfIntersectionsParams
    | EmptyObject;

interface Point {
    x: number;
    y: number;
    z: number;
}

type Vector = Point;

interface GtmMeshTranslationParams {
    delta: Vector;
}

interface GtmMeshRemoveTrianglesParams {
    triInds: number[];
}

interface GtmMeshClipParams {
    minPoint: Point;
    maxPoint: Point;
    rotationAngle: number;
}

interface GtmCreateAnalyticalBoundaryParams extends GtmMeshClipParams {
    projectName: string;
}

interface GtmInitAggregateGeomParams {
    minPoint: Point;
    maxPoint: Point;
    rotationAngle: number;
    boundaryId: ObjectIdWithVersion;
}

interface GtmAddToAggregateGeomParams {
    aggregateGeomId: ObjectIdWithVersion;
    tolerance: number;
    noSelfIntersectionsInParts: boolean;
}

export interface GtmMeshRemoveDegenerateTriangleParams {
    needleThresholdRatio: number;
    capMinAngleRad: number;
    needleCollapseLength: number;
}

export enum GtmMeshFillMode {
    Fill = 'Fill',
    FillAndRefine = 'FillAndRefine',
    FillRefineAndFair = 'FillRefineAndFair',
}

export interface GtmMeshFillHoleParams {
    fillMode: GtmMeshFillMode;
    edges: [number, number] | [number, number][];
}

interface GtmUploadMeshDataParams {
    file_id: string;
    name: string;
}

export type GtmMeshTransformationParams =
    | GtmMeshTranslationParams
    | GtmMeshRemoveTrianglesParams
    | GtmMeshClipParams
    | GtmInitAggregateGeomParams
    | GtmAddToAggregateGeomParams
    | GtmMeshRemoveDegenerateTriangleParams
    | GtmMeshFillHoleParams
    | GtmCreateAnalyticalBoundaryParams
    | GtmUploadMeshDataParams
    | EmptyObject;

type GtmMeshRequestParams =
    | GtmMeshGetMeshDataParams
    | GtmMeshDetectorParams
    | GtmMeshTransformationParams;

export interface ObjectIdWithVersion {
    id: string;
    version: string;
    name?: string;
}

export type GtmObjects = string[] | ObjectIdWithVersion[];

export interface GtmBaseRequestBody {
    gooseContext: GtmGooseContext;
    action: GtmMeshRequestAction;
    objects: GtmObjects;
    params: GtmMeshRequestParams;
}

export interface GtmMeshDataRequestBody extends GtmBaseRequestBody {
    action: GtmMeshGetMeshDataAction;
    params: GtmMeshGetMeshDataParams;
}

export interface GtmMeshData {
    vertices: Float32Array;
    triangles: Int32Array;
}

export interface GtmMeshDetectorRequestBody extends GtmBaseRequestBody {
    action: GtmMeshDetectorAction;
    params: GtmMeshDetectorParams;
}

export interface GtmMeshDetectionData
    extends Array<{
        points: number[];
        edges: [number, number][];
        triangles: number[];
    }> {}

export interface GtmMeshTransformationRequestBody extends GtmBaseRequestBody {
    action: GtmMeshTransformationAction;
    params: GtmMeshTransformationParams;
}

interface GtmObject {
    id: string;
    version: string;
}

export interface GtmMeshTransformationResponse {
    created: GtmObject[];
    modified: GtmObject[];
    deleted: GtmObject[];
}

export interface GtmMeshTransformationApiResponse {
    created: string[];
    modified: string[];
    deleted: string[];
}

export interface GtmGooseContext {
    host: string;
    apiToken: string;
    orgId: string;
    workspaceId: string;
}

// These must match the possible values defined by the Task API
export enum JobStatus {
    Requested = 'requested',
    InProgress = 'in progress',
    Cancelling = 'cancelling',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Cancelled = 'cancelled',
}

export interface TaskStatusResponse {
    job_status: {
        status: JobStatus;
        progress: number;
        message: string;
        error: string | null;
    };
    links: {
        [key: string]: string;
    };
}
