import CircularProgress from '@mui/material/CircularProgress';
import MaterialDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import { useStyles } from './BoundingBoxDialog.styles';

export interface DialogContentProps {
    dialogContent: string | JSX.Element;
}

export interface BoundingBoxModalType {
    open: boolean;
    dialogContent: string | JSX.Element;
}

function Title() {
    return (
        <Typography variant="h5" padding="4px">
            Executing transformations ...
        </Typography>
    );
}

function Description({ dialogContent }: DialogContentProps) {
    const { classes } = useStyles();
    return (
        <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    {dialogContent && (
                        <Typography component="div" classes={{ root: classes.contentText }}>
                            {dialogContent}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </DialogContent>
    );
}

export function BoundingBoxModal({ open, dialogContent }: BoundingBoxModalType) {
    const { classes } = useStyles();

    return (
        <MaterialDialog
            open={open}
            maxWidth={false}
            classes={{ paper: classnames(classes.dialogContainer) }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                wrap="nowrap"
            >
                <Grid item xs={12}>
                    <Title />
                    <Grid item container direction="row">
                        <CircularProgress />
                        <Description dialogContent={dialogContent} />
                    </Grid>
                </Grid>
            </Grid>
        </MaterialDialog>
    );
}
