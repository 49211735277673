import { LandingPage, LoginHandler, LandingPageProps } from '@local/login';
import { OrgRouteGuard } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useRoutes } from 'react-router-dom';

import { ContentScaffolding } from './components/contentScaffolding/ContentScaffolding';
import { NotFoundPage } from './pages/notFoundPage/NotFoundPage';
import { store } from './store/store';
import { NOT_FOUND_MESSAGE, NOT_FOUND_TITLE } from './strings';
import { Visualization } from './visualization/Visualization/Visualization';

export function TemplateRoutes(): ReturnType<typeof useRoutes> {
    const routes = useRoutes([
        {
            path: '/',
            element: (
                <OrgRouteGuard>
                    <ContentScaffolding />
                </OrgRouteGuard>
            ),
            children: [
                {
                    path: ':orgUuid',
                    element: <Visualization />,
                    children: [
                        {
                            path: 'workspace/:workspaceUuid',
                            element: <Visualization />,
                            children: [
                                {
                                    // TODO: GEOM-137 - Wrap visualization in a project context here.
                                    path: 'project/:projectName',
                                    element: <Visualization />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <NotFoundPage title={NOT_FOUND_TITLE} message={NOT_FOUND_MESSAGE} />,
        },
    ]);

    return routes;
}

export const App = () => (
    <LoginHandler
        guardedRoutes={<TemplateRoutes />}
        LandingPage={({ getSignInUrl }: LandingPageProps) => (
            <LandingPage getSignInUrl={getSignInUrl} createId={false} />
        )}
        LandingPageLogo={undefined} // `undefined` results in the display of the default Seequent logo.
        store={store}
        service="evo"
        useOrgSelector
        bentleyIdEnabled
        additionalScopes={[
            'evo.discovery',
            'evo.file',
            'evo.object',
            'evo.workspace',
            'offline_access',
        ]}
    />
);
