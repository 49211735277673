/* eslint-disable no-param-reassign */

import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

import { initialState } from './selectors';
import { VisualizationState, SceneObjectData } from './visualizationSlice.types';

export const visualizationSlice: Slice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        updateOverlays(state: VisualizationState, { payload }: PayloadAction<VisualizationState>) {
            state.overlays = { ...state.overlays, ...payload };
        },
        addOrUpdateSceneObject(
            state: VisualizationState,
            { payload: [objectId, objectData] }: PayloadAction<[string, SceneObjectData]>,
        ) {
            const initialData = state.sceneObjectMap[objectId];
            state.sceneObjectMap[objectId] = { ...initialData, ...objectData };
        },
        removeSceneObject(state: VisualizationState, { payload }: PayloadAction<string>) {
            delete state.sceneObjectMap[payload];
        },
        clearSceneObjects(state: VisualizationState) {
            state.sceneObjectMap = {};
        },
    },
});

export const { updateOverlays, addOrUpdateSceneObject, removeSceneObject, clearSceneObjects } =
    visualizationSlice.actions;
