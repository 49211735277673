import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    DownloadFileResponse,
    useGetFileByPathQuery,
} from 'src/apiClients/file/GENERATED_fileClientEndpoints';
import { MESH_SCHEMA } from 'src/constants';
import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useProjectManager } from 'src/hooks/project';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { useSceneObjectSelectionManager } from 'src/hooks/useSceneObjectSelectionManager';
import { selectCurrentProjectName } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjectIds } from 'src/store/visualization/selectors';

import { ObjectListItemControl } from './ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './ProjectTreePanel.styles';
import { UploadObjectsButton } from './UploadObjectsButton/UploadObjectsButton';

export function SkeletonArtifactsPanelContents() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.content}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

export function ProjectTreePanel() {
    const { classes } = useStyles();
    const { projectName, workspaceUuid, orgUuid } = useParams();
    const selectedObjectIds = useAppSelector(selectedSceneObjectIds);
    const currentProjectName = useAppSelector(selectCurrentProjectName);
    const { clearGtmObjects } = useSceneObjectDataManager();
    const [currentObjectList, setCurrentObjectList] = useState<GtmEvoOutputObject[]>([]);

    const { data: fileData } = useGetFileByPathQuery({
        organisationId: orgUuid || '',
        workspaceId: workspaceUuid || '',
        filePath: projectName || '',
    });

    const { fetchObjects, isLoading: isObjectsLoading } = useProjectManager();

    const { onObjectControlSelection } = useSceneObjectSelectionManager();

    const filterMeshes = (objects: GtmEvoOutputObject[]) =>
        objects.filter((object) => object.schema === MESH_SCHEMA);

    async function loadObjects(fileResponse?: DownloadFileResponse) {
        const objectsList = await fetchObjects(fileResponse);
        if (objectsList) {
            setCurrentObjectList(filterMeshes(objectsList));
        }
    }

    useEffect(() => {
        if (projectName !== `${currentProjectName}.gtm`) {
            clearGtmObjects();
            loadObjects(fileData);
        }
    }, [projectName, isObjectsLoading, workspaceUuid, fileData]);

    const handleObjectUpload = (object: GtmEvoOutputObject) => {
        setCurrentObjectList((list) => {
            const index = list.findIndex((obj) => obj.id === object.id);
            const objectAlreadyExists = index !== -1;
            let newObjects: GtmEvoOutputObject[] = [];
            if (objectAlreadyExists) {
                newObjects = [...list];
                newObjects[index] = object;
            } else {
                newObjects = [...list, object];
            }

            return filterMeshes(newObjects);
        });
    };

    return (
        <Grid item xs className={classnames(classes.contentsContainer, classes.content)}>
            <Grid item automation-id="upload-obj-files">
                <UploadObjectsButton
                    buttonText="Upload objects"
                    onObjectUpload={handleObjectUpload}
                    disabled={false}
                />
            </Grid>
            {currentObjectList ? (
                currentObjectList?.map((child: GtmEvoOutputObject, index: number) => (
                    <Grid
                        key={child.id}
                        onClick={(event) =>
                            onObjectControlSelection(currentObjectList, index, child.id, event)
                        }
                    >
                        <ObjectListItemControl
                            objectListItem={child}
                            selectedObjectIds={selectedObjectIds}
                            addToSceneOnMount
                        />
                    </Grid>
                ))
            ) : (
                <SkeletonArtifactsPanelContents />
            )}
        </Grid>
    );
}
