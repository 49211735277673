import queryString from 'query-string';
import {
    ArrayParam,
    BooleanParam,
    encodeQueryParams,
    NumberParam,
    StringParam,
} from 'serialize-query-params';

import { Filter } from '../types';
import { filterByProperty } from '../utils/filterByProperty';
import {
    DeleteObjectsByIdApiArg,
    DeleteObjectsByIdApiResponse,
    gooseClientEndpoints,
    ListObjectsApiArg,
    ListObjectsApiResponse,
    ListObjectsByOrgApiArg,
    ListObjectsByOrgApiResponse,
    ListObjectsResponse,
} from './GENERATED_gooseClientEndpoints';

export interface ListObjectsSchemaArg {
    orgId: string;
    workspaceId: string;
    folderSchema: Filter[];
}

const injectedRtkApi = gooseClientEndpoints
    .injectEndpoints({
        endpoints: (build) => ({
            getObjectsByFilter: build.query<any, ListObjectsSchemaArg>({
                async queryFn(
                    { orgId, folderSchema, workspaceId },
                    _queryApi,
                    _extraOptions,
                    baseQuery,
                ) {
                    const response = await baseQuery({
                        url: `/geoscience-object/orgs/${orgId}/workspaces/${workspaceId}/objects`,
                        method: 'GET',
                    });

                    if (response.error) {
                        return { error: response.error };
                    }

                    const data = response.data as ListObjectsResponse;
                    const filteredResponse = filterByProperty(data, folderSchema);
                    return { data: filteredResponse };
                },
            }),
            /**
             * Add preview api header which is required until this API is in production.
             */
            deleteObjectsById: build.mutation<
                DeleteObjectsByIdApiResponse,
                DeleteObjectsByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects/${queryArg.objectId}`,
                    method: 'DELETE',
                    headers: {
                        'API-Preview': 'opt-in',
                    },
                }),
            }),
            /**
             * We are overriding this endpoint as the generated RTK Query endpoint doesn't support repeated key-value
             * format for multi value parameters in query strings.
             */
            listObjects: build.query<ListObjectsApiResponse, ListObjectsApiArg>({
                query: (queryArg) => {
                    const baseUrl = `/geoscience-object/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/objects`;
                    // ArrayParam allows array type parameters to be converted to repeated key-value pairs in the query
                    // string. i.e. param=value1,value2 => "param=value1&param=value2"
                    const listQueryString = queryString.stringify(
                        encodeQueryParams(
                            {
                                limit: NumberParam,
                                offset: NumberParam,
                                order_by: StringParam,
                                author: ArrayParam,
                                created_by: ArrayParam,
                                created_at: ArrayParam,
                                object_name: ArrayParam,
                                schema_id: ArrayParam,
                            },
                            {
                                limit: queryArg.limit,
                                offset: queryArg.offset,
                                order_by: queryArg.orderBy,
                                author: queryArg.author,
                                created_by: queryArg.createdBy,
                                created_at: queryArg.createdAt,
                                object_name: queryArg.objectName,
                                schema_id: queryArg.schemaId,
                            },
                        ),
                    );
                    return `${baseUrl}?${listQueryString}`;
                },
            }),
            /**
             * Customised in order to support arrays of values in query string params, as well as add the preview API
             * header, which is required until this API is in production.
             */
            listObjectsByOrg: build.query<ListObjectsByOrgApiResponse, ListObjectsByOrgApiArg>({
                async queryFn(queryArg, _queryApi, _extraOptions, baseQuery) {
                    const listQueryString = queryString.stringify(
                        encodeQueryParams(
                            {
                                limit: NumberParam,
                                offset: NumberParam,
                                order_by: StringParam,
                                author: ArrayParam,
                                created_by: ArrayParam,
                                created_at: ArrayParam,
                                object_name: ArrayParam,
                                schema_id: ArrayParam,
                                geojson_bounding_box: ArrayParam,
                                deleted: BooleanParam,
                                permitted_workspaces_only: BooleanParam,
                            },
                            {
                                limit: queryArg.limit,
                                offset: queryArg.offset,
                                order_by: queryArg.orderBy,
                                created_by: queryArg.createdBy,
                                created_at: queryArg.createdAt,
                                object_name: queryArg.objectName,
                                schema_id: queryArg.schemaId,
                                geojson_bounding_box: queryArg.geojsonBoundingBox,
                                deleted: queryArg.deleted,
                                permitted_workspaces_only: queryArg.permittedWorkspacesOnly,
                            },
                        ),
                    );

                    const response = await baseQuery({
                        url: `/geoscience-object/orgs/${queryArg.orgId}/objects?${listQueryString}`,
                        method: 'GET',
                        headers: {
                            'API-Preview': 'opt-in',
                        },
                    });

                    if (response.error) {
                        return { error: response.error };
                    }

                    const data = response.data as ListObjectsByOrgApiResponse;
                    return { data };
                },
            }),
        }),
        overrideExisting: true,
    })
    .enhanceEndpoints({
        addTagTypes: ['Object'],
        endpoints: {
            getObjectById: {
                providesTags: ['Object'],
            },
            getObjectsByFilter: {
                providesTags: ['Object'],
            },
            listObjects: {
                providesTags: ['Object'],
            },
            listObjectsByOrg: {
                providesTags: ['Object'],
            },
            deleteObjectsById: {
                invalidatesTags: ['Object'],
            },
        },
    });

export { injectedRtkApi as gooseClient };

export const { useGetObjectsByFilterQuery, useLazyGetObjectsByFilterQuery } = injectedRtkApi;

export * from './GENERATED_gooseClientEndpoints';
