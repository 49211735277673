import { common } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        root: {
            ...common(theme).thinScrollBar,
            padding: theme.spacing(1, 2),
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '156px',
        },
        paramIcon: {
            width: '16px',
            height: '16px',
        },
        titleFont: {
            paddingLeft: theme.spacing(1),
            fontSize: '15px',
            fontWeight: fonts.weights.medium,
            color: theme.palette.grey[600],
        },
        messageFont: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
            color: theme.palette.grey[600],
            paddingBottom: theme.spacing(1),
        },
        paramButton: {
            borderRadius: theme.spacing(3),
        },
        paramButtonText: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
            color: theme.palette.grey[700],
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        paramDialogRoot: {
            paddingBottom: theme.spacing(2),
        },
        boxWidth: {
            width: theme.spacing(12.5),
        },
        loadingProgressContainer: {
            padding: `${theme.spacing(0.5)} 0`,
        },
        successfulText: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
            color: theme.palette.success.main,
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        errorText: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
            color: theme.palette.error.main,
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
