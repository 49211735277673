import { fonts } from '@local/web-design-system/dist/styles/fonts';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            width: '100%',
        },
        container: {
            padding: theme.spacing(1, 1),
            flexDirection: 'column',
            alignItems: 'center',
        },
        boundaryNameSection: {
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        boxControl: {
            flexGrow: 1,
            padding: theme.spacing(0.5),
        },
        coordsInputRow: {
            justifyContent: 'flex-end',
            flexWrap: 'nowrap',
        },
        minMaxTitles: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(1.5),
        },
        deleteButtonSection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        dialogButtons: {
            gridRowGap: theme.spacing(),
        },
        unselectedMessage: {
            fontSize: '14px',
            fontWeight: fonts.weights.regular,
            padding: theme.spacing(1, 2),
        },
        deleteButton: {
            filter: 'invert(35%) sepia(100%) saturate(6000%) hue-rotate(360deg) brightness(100%) contrast(100%)',
        },
        progressContainer: {
            width: '100%',
        },
        loadingProgressContainer: {
            padding: theme.spacing(1, 0),
        },
        dialogContainer: {
            borderRadius: 0,
            width: theme.spacing(70),
            padding: theme.spacing(2),
        },
        title: {
            ...fonts.h5,
            padding: theme.spacing(3, 2, 1),
        },
        dialogContent: {
            padding: theme.spacing(0, 5, 3, 2),
        },
        contentText: {
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '21px',
            marginBottom: 0,
            paddingTop: theme.spacing(1),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
