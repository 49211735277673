import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { WDSThemeProvider } from '@local/web-design-system-2/dist/theme';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { PanelHeader } from '@local/web-design-system/dist/components/PanelHeader';
import { DeleteIcon } from '@local/web-design-system/dist/icons/Actions/DeleteIcon';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';
import { CameraState, Nullable, PlotState } from '@local/webviz/dist/types/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import round from 'lodash-es/round';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/file/customFileEndpoints';
import { DownloadFileResponse } from 'src/apiClients/file/GENERATED_fileClientEndpoints';
import { useLazyListObjectsQuery, ListedObject } from 'src/apiClients/goose/extendedGooseClient';
import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AGGREGATE_GEOMETRY_NAME, GTM_FOLDER_PREFIX, MESH_SCHEMA } from 'src/constants';
import {
    GtmProject,
    GtmEvoFileType,
    GtmEvoOutputObject,
    GtmProjectInput,
} from 'src/gtmProject/Project.types';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import { ERROR_CREATING_PROJECT, PROJECT_EXTENSION } from 'src/strings';
import { boundingBoxToGtmBounds, rgbArrayToGtmColor } from 'src/utils/typeTransformations';
import { initialColorGenerator } from 'src/visualization/context/generateData';

import {
    BOUNDING_BOX_TITLE,
    NAME_TITLE,
    BOUNDING_BOX_DEFAULT_NAME,
    MAX_TITLE,
    MIN_TITLE,
    X_LABEL,
    Y_LABEL,
    Z_LABEL,
    NO_SELECTION_TEXT,
    ACCEPT,
} from './BoundingBoxDialog.constants';
import { useStyles } from './BoundingBoxDialog.styles';
import { BoxControlProps, BoundingBox } from './BoundingBoxDialog.types';
import { BoundingBoxModal } from './BoundingBoxModal';
import {
    START_AGGREGATE_GEOM_MESSAGE,
    START_ANALYTICAL_BOUNDARY_CREATION_MESSAGE,
    START_LOADING_NEW_PROJECT,
    START_SEPARATE_VOLUME_MESSAGE,
    START_UPLOAD_MESSAGE,
} from './BoundingBoxModal.constants';
import {
    computeBoundingBoxFromCenter,
    computeBoundingBoxVertices,
    getBoundingBoxSnapshot,
} from './snapshot';

function BoxControl({ label = '', value, onChange, classes }: BoxControlProps) {
    return (
        <Grid container item alignItems="center" xs wrap="nowrap" className={classes.boxControl}>
            <TextField
                variant="outlined"
                label={label}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value ? parseFloat(event.target.value) : 0);
                }}
            />
        </Grid>
    );
}

export function BoundingBoxDialog({
    existingProjectNames,
    onClose,
    onBoundaryCreated,
}: {
    existingProjectNames: string[];
    onClose: () => void;
    onBoundaryCreated: (fileData: DownloadFileResponse) => void;
}) {
    const sceneObjects = useAppSelector(sceneObjectMap);
    const params = useParams();
    const { getEntityState, setStateFromSnapshot } = useBaseXyz();
    const { selectionState } = useSelection();
    const { views } = getEntityState('plot') as any;
    const { classes } = useStyles();
    const gooseContext = useGooseContext();
    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [updateFile] = useCustomUpsertFileByPathMutation();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const [GooseListObjectsTrigger] = useLazyListObjectsQuery();

    const [boxId, setBoxId] = useState<Nullable<string>>(null);
    const [boundaryName, setBoundaryName] = useState<string>(BOUNDING_BOX_DEFAULT_NAME);
    const [boundingBox, setBoundingBox] = useState<BoundingBox | undefined>(undefined);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [isClipping, setIsClipping] = useState<boolean>(false);

    const { addMessage } = useMessagesContext();

    function updateBoundingBoxIfItExists(updatedValue: Partial<BoundingBox>) {
        if (boundingBox && updatedValue) {
            setBoundingBox((currentBoundingBox) => ({
                ...currentBoundingBox!,
                ...updatedValue,
            }));
        }
    }

    function removeBoundingBoxIfItExists() {
        if (boundingBox) {
            // Don't use the captured 'views'. It may have changed in the time between the click and the close
            // ie: the event handler.
            const existingViews = (getEntityState('plot') as PlotState).views;
            // Remove the box from the plot views. Note that it is not necessarily in a particular
            // position in the array.
            existingViews.splice(existingViews.indexOf(boxId!), 1);
            setStateFromSnapshot({ plot: { views: existingViews } }, {});
            setBoundingBox(undefined);
        }
    }

    function closeDialog() {
        removeBoundingBoxIfItExists();
        onClose();
    }

    async function uploadNewProject(project: GtmProject) {
        const fileName = `${project.name}.${PROJECT_EXTENSION}`;
        return updateFile({
            workspaceId,
            organisationId,
            filePath: fileName,
            uploadFile: new File(
                [new Blob([JSON.stringify(project)], { type: 'application/json' })],
                fileName,
            ),
        });
    }

    async function handleError(error: unknown, projectName: string) {
        addMessage({
            message: ERROR_CREATING_PROJECT,
            type: NotificationType.ERROR,
        });
        trackError(`Error: ${error} creating project "${projectName}"`);
        return Promise.reject(error);
    }

    async function makeOutputObjectsFromCreated(
        objects: GtmProjectInput[],
    ): Promise<GtmEvoOutputObject[]> {
        // We need the objects' name which we don't have, so we have to list to get it :(
        const { data: objectListing, isError } = await GooseListObjectsTrigger({
            orgId: organisationId,
            workspaceId,
            objectName: [`ilike:${GTM_FOLDER_PREFIX}/${boundaryName}*`],
        });

        if (isError || !objectListing) {
            return Promise.reject(new Error('Error listing objects for metadata generation'));
        }

        try {
            const outputObjects = objectListing.objects.map((object: ListedObject) => {
                const createdObject = objects.find((obj) => obj.object_id === object.object_id);
                if (!createdObject) {
                    throw new Error(
                        `Error: created object with id '${object.object_id}' not found`,
                    );
                }

                return {
                    type: GtmEvoFileType.GeoscienceObject,
                    name: object.name,
                    id: createdObject.object_id,
                    version_id: createdObject.version_id,
                    schema: object.schema,
                    color: rgbArrayToGtmColor(initialColorGenerator()),
                };
            });
            return outputObjects;
        } catch (error) {
            return await handleError(error, boundaryName);
        }
    }

    function createNewProject(
        projectName: string,
        inputObjects: GtmProjectInput[],
        objects: GtmEvoOutputObject[],
    ) {
        const project: GtmProject = {
            name: projectName,
            inputs: inputObjects,
            analytical_models: [
                {
                    bounds: boundingBoxToGtmBounds(boundingBox!),
                    objects,
                },
            ],
        };

        return project;
    }

    async function createAnalyticalBoundary(
        inputObjects: GtmProjectInput[],
    ): Promise<GtmProjectInput[]> {
        if (!gooseContext) {
            // Shouldn't actually happen since we have an org route guard.
            trackError('Error: No goose context');
            throw new Error('No goose context');
        }

        const creationParams = {
            ...boundingBoxToGtmBounds(boundingBox!),
            // In the future we should have a boundary name and a project name.
            // but at the moment we create a project with the boundary name.
            projectName: boundaryName,
        };

        const clipToBoundaryPromises = inputObjects.map(({ object_id, version_id }) => {
            const body = formGtmMeshTransformationBody(
                gooseContext,
                GtmMeshTransformationAction.CreateAnalyticalBoundary,
                [{ id: object_id, version: version_id }],
                creationParams,
            );
            return GtmMeshTransformationTrigger(body);
        });

        const clipToBoundaryResults = await Promise.all(clipToBoundaryPromises);

        if (clipToBoundaryResults.some(({ isError }) => isError)) {
            // Probably shouldn't error out completely.
            // Could report which surface failed.
            return Promise.reject(new Error('Error creating the analytical boundary.'));
        }

        const createdObjects = clipToBoundaryResults.map(({ data }) => data?.created || []).flat();

        if (createdObjects.length) {
            return createdObjects.map(({ id, version }) => ({
                object_id: id,
                version_id: version,
            }));
        }

        return Promise.reject(
            new Error(
                'Analytical boundary creation succeeded but no objects are within the bounds.',
            ),
        );
    }

    async function updateAggregateGeomBackend(
        inputMeshes: GtmProjectInput[],
    ): Promise<GtmProjectInput> {
        const { data: result, isError } = await GtmMeshTransformationTrigger(
            formGtmMeshTransformationBody(
                gooseContext!,
                GtmMeshTransformationAction.InitAggregateGeom,
                [],
                {
                    ...boundingBoxToGtmBounds(boundingBox!),
                },
            ),
        );

        if (!isError && result?.created?.length) {
            const tolerance = 1e-6; // TODO: a project setting? a better number?
            const aggregateGeomId = result.created[0];
            const inputMeshesArray = [
                ...inputMeshes.map(({ object_id, version_id }) => ({
                    id: object_id,
                    version: version_id,
                })),
            ];
            const { data: addResult, isError: addIsError } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(
                    gooseContext!,
                    GtmMeshTransformationAction.AddToAggregateGeom,
                    inputMeshesArray,
                    {
                        aggregateGeomId,
                        tolerance,
                        noSelfIntersectionsInParts: false,
                    },
                ),
            );

            if (!addIsError && addResult?.modified?.length) {
                const object = addResult.modified[0];
                return {
                    object_id: object.id,
                    version_id: object.version,
                };
            }
        }
        return Promise.reject(new Error('Error making aggregate geometry'));
    }

    async function updateAggregateGeom(project: GtmProject, inputMeshes: GtmProjectInput[]) {
        const updatedProject = project;

        const { object_id: aggregateId, version_id: aggregateVersion } =
            await updateAggregateGeomBackend(inputMeshes);

        // This is a kludge to get a default color for us devs.
        const initialColor = initialColorGenerator();

        updatedProject.analytical_models[0].composite_model = {
            name: AGGREGATE_GEOMETRY_NAME,
            id: aggregateId,
            version_id: aggregateVersion,
            type: GtmEvoFileType.GeoscienceObject,
            schema: MESH_SCHEMA,
            color: rgbArrayToGtmColor(initialColor),
        };

        return updatedProject;
    }

    async function separateVolumesBackend(aggregateMesh: GtmEvoOutputObject) {
        const { data: result, isError } = await GtmMeshTransformationTrigger(
            formGtmMeshTransformationBody(
                gooseContext!,
                GtmMeshTransformationAction.SeparateVolumes,
                [{ id: aggregateMesh.id, version: aggregateMesh.version_id }],
                {},
            ),
        );

        if (!isError && result?.created?.length) {
            return result.created.map(({ id, version }) => ({
                id,
                version_id: version,
            }));
        }
        return Promise.reject(new Error('Error separating volumes from aggregate geometry'));
    }

    async function separateVolumes(project: GtmProject) {
        const updatedProject = project;
        updatedProject.analytical_models[0].volumes = [];
        const aggregateMesh = project.analytical_models[0].composite_model;
        if (!aggregateMesh) {
            return updatedProject;
        }
        const response = await separateVolumesBackend(aggregateMesh);
        // This is a kludge to get a default color for us devs.
        const initialColor = initialColorGenerator();
        updatedProject.analytical_models[0].volumes = response.map((item, index) => ({
            type: GtmEvoFileType.GeoscienceObject,
            name: `Volume ${index + 1}`,
            id: item.id,
            version_id: item.version_id,
            schema: MESH_SCHEMA,
            color: rgbArrayToGtmColor(initialColor),
        }));

        return updatedProject;
    }

    async function handleCreateBoundary() {
        // TODO: GEOM-108 - Validate the bounds

        const inputObjects: GtmProjectInput[] = Object.keys(sceneObjects)
            .filter((objectId) => views.includes(objectId))
            .map((objectId) => {
                const sceneObject = sceneObjects[objectId];
                return {
                    object_id: sceneObject.objectId,
                    version_id: sceneObject.versionId,
                };
            });

        try {
            setIsClipping(true);

            setModalMessage(START_ANALYTICAL_BOUNDARY_CREATION_MESSAGE);

            const objectsInBoundary = await createAnalyticalBoundary(inputObjects);

            if (objectsInBoundary) {
                const projectObjects = await makeOutputObjectsFromCreated(objectsInBoundary);
                let project = createNewProject(boundaryName, inputObjects, projectObjects);

                // TODO: aggregate when user clicks on 'Aggregate' button
                setModalMessage(START_AGGREGATE_GEOM_MESSAGE);
                project = await updateAggregateGeom(project, objectsInBoundary);

                setModalMessage(START_SEPARATE_VOLUME_MESSAGE);
                project = await separateVolumes(project);

                setModalMessage(START_UPLOAD_MESSAGE);
                const uploadResponse = await uploadNewProject(project);
                if (uploadResponse.data) {
                    onBoundaryCreated(uploadResponse.data);
                    setModalMessage(START_LOADING_NEW_PROJECT);
                }
            }
        } catch (error) {
            await handleError(error, boundaryName);
        } finally {
            setIsClipping(false);
        }

        closeDialog();
    }

    useEffect(() => {
        removeBoundingBoxIfItExists();

        if (selectionState?.position) {
            const randomBoxId = Math.floor(Math.random() * 100).toString(); // Random number to create a unique box id (temporary solution)
            setBoxId(randomBoxId);
            const { radius } = getEntityState('camera') as CameraState;
            const box = computeBoundingBoxFromCenter(selectionState.position, radius / 10);
            setBoundingBox(box);
            const snapshot = getBoundingBoxSnapshot({ label: randomBoxId!, box });
            const plotViewSnapshot = {
                ...snapshot,
                plot: { views: [...views, randomBoxId] },
            };
            if (!plotViewSnapshot) {
                return;
            }
            setStateFromSnapshot(plotViewSnapshot, {});
        }
    }, [selectionState]);

    useEffect(() => {
        if (boundingBox) {
            const boundingVertices = computeBoundingBoxVertices(boundingBox);
            const elementId = `bounding-box-${boxId}`;
            const snapshot = { [elementId]: { vertices: boundingVertices } };
            setStateFromSnapshot(snapshot, {});
        }
    }, [boundingBox]);

    const projectNameExists = existingProjectNames.includes(`${boundaryName}.${PROJECT_EXTENSION}`);

    return (
        <>
            <PanelHeader
                title={BOUNDING_BOX_TITLE}
                onClose={() => {
                    closeDialog();
                }}
            />
            <WDSThemeProvider themeMode="dark">
                {!boundingBox && (
                    <Typography
                        variant="body1"
                        align="center"
                        className={classes.unselectedMessage}
                    >
                        {NO_SELECTION_TEXT}
                    </Typography>
                )}

                {boundingBox && (
                    <Grid container className={classes.root}>
                        <BoundaryNameSection
                            isCurrentTextValid={!projectNameExists}
                            onChange={(event) => setBoundaryName(event.target.value)}
                        />
                        <CoordinatesInputSection
                            boundingBox={boundingBox}
                            onChange={updateBoundingBoxIfItExists}
                        />
                        <ButtonsSection
                            isCreateDisabled={projectNameExists}
                            onCreateBoundary={handleCreateBoundary}
                            onDeleteBoundary={closeDialog}
                        />
                    </Grid>
                )}

                <BoundingBoxModal open={isClipping} dialogContent={modalMessage} />
            </WDSThemeProvider>
        </>
    );
}

function BoundaryNameSection({
    isCurrentTextValid,
    onChange,
}: {
    isCurrentTextValid: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const { classes } = useStyles();

    return (
        <Grid container className={classes.boundaryNameSection} wrap="nowrap">
            <Grid item xs={11}>
                <TextField
                    label={NAME_TITLE}
                    variant="outlined"
                    onChange={onChange}
                    defaultValue={BOUNDING_BOX_DEFAULT_NAME}
                    error={!isCurrentTextValid}
                    helperText={isCurrentTextValid ? undefined : 'The boundary name already exists'}
                />
            </Grid>
        </Grid>
    );
}

function CoordinatesInputSection({
    boundingBox,
    onChange,
}: {
    boundingBox: BoundingBox;
    onChange: (updatedValue: Partial<BoundingBox>) => void;
}) {
    const { classes } = useStyles();

    const minRow = (
        <Grid item container direction="row">
            <Grid item>
                <Typography variant="body2" className={classes.minMaxTitles}>
                    {MIN_TITLE}
                </Typography>
            </Grid>
            <Grid item container xs={11} className={classes.coordsInputRow}>
                <Grid item>
                    <BoxControl
                        label={X_LABEL}
                        value={round(boundingBox.xMin || 0)}
                        onChange={(value) => {
                            if (value < boundingBox.xMax) {
                                onChange({ xMin: value });
                            }
                        }}
                        classes={classes}
                    />
                </Grid>
                <Grid item>
                    <BoxControl
                        label={Y_LABEL}
                        value={round(boundingBox.yMin || 0)}
                        onChange={(value) => {
                            if (value < boundingBox.yMax) {
                                onChange({ yMin: value });
                            }
                        }}
                        classes={classes}
                    />
                </Grid>
                <Grid item>
                    <BoxControl
                        label={Z_LABEL}
                        value={round(boundingBox.zMin || 0)}
                        onChange={(value) => {
                            if (value < boundingBox.zMax) {
                                onChange({ zMin: value });
                            }
                        }}
                        classes={classes}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    const maxRow = (
        <Grid item container direction="row">
            <Grid item>
                <Typography variant="body2" className={classes.minMaxTitles}>
                    {MAX_TITLE}
                </Typography>
            </Grid>
            <Grid item container xs={11} className={classes.coordsInputRow}>
                <Grid item>
                    <BoxControl
                        label={X_LABEL}
                        value={round(boundingBox.xMax || 0)}
                        onChange={(value) => {
                            if (value > boundingBox.xMin) {
                                onChange({ xMax: value });
                            }
                        }}
                        classes={classes}
                    />
                </Grid>
                <Grid item>
                    <BoxControl
                        label={Y_LABEL}
                        value={round(boundingBox.yMax || 0)}
                        onChange={(value) => {
                            if (value > boundingBox.yMin) {
                                onChange({ yMax: value });
                            }
                        }}
                        classes={classes}
                    />
                </Grid>
                <Grid item>
                    <BoxControl
                        label={Z_LABEL}
                        value={round(boundingBox.zMax || 0)}
                        onChange={(value) => {
                            if (value > boundingBox.zMin) {
                                onChange({ zMax: value });
                            }
                        }}
                        classes={classes}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid item container className={classes.container}>
            {minRow}
            {maxRow}
        </Grid>
    );
}

function ButtonsSection({
    isCreateDisabled,
    onCreateBoundary,
    onDeleteBoundary,
}: {
    isCreateDisabled: boolean;
    onCreateBoundary: () => Promise<void>;
    onDeleteBoundary: () => void;
}) {
    const { classes } = useStyles();

    return (
        <Grid container className={classes.container} marginBottom={1}>
            <Grid container wrap="nowrap" className={classes.dialogButtons}>
                <Divider light />
                <Grid item xs={10}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={onCreateBoundary}
                        disabled={isCreateDisabled}
                    >
                        {ACCEPT}
                    </Button>
                </Grid>
                <Grid item xs={2} className={classes.deleteButtonSection}>
                    <IconButton onClick={onDeleteBoundary} className={classes.deleteButton}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
