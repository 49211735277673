import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/file/customFileEndpoints';
import { useDeleteFileByIdMutation } from 'src/apiClients/file/enhancedFileMiddleware';
import { DownloadFileResponse } from 'src/apiClients/file/GENERATED_fileClientEndpoints';
import { useLazyListObjectsQuery } from 'src/apiClients/goose/extendedGooseClient';
import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { MESH_SCHEMA } from 'src/constants';
import { GtmEvoFileType, GtmEvoOutputObject, GtmProject } from 'src/gtmProject/Project.types';
import { useGooseContext } from 'src/hooks/useGooseContext';
import {
    clearCurrentProject,
    setBaseArtifacts,
    setCurrentProject,
    setCurrentProjectVersionId,
} from 'src/store/project/projectSlice';
import { selectBaseArtifactsObjects } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { PROJECT_EXTENSION, ERROR_UPLOADING_PROJECT } from 'src/strings';
import { fileNameExtensionRemover } from 'src/utils/fileManagement';
import { rgbArrayToGtmColor } from 'src/utils/typeTransformations';
import { initialColorGenerator } from 'src/visualization/context/generateData';
import { BASE_MODEL } from 'src/visualization/Visualization/ArtifactsPanel/ArtifactsPanel.constants';

export function useProjectManager() {
    const { orgUuid, workspaceUuid, projectName } = useParams();
    const dispatch = useDispatch();
    const { addMessage } = useMessagesContext();
    const [UpdateFileTrigger] = useCustomUpsertFileByPathMutation();
    const [DeleteFileTrigger] = useDeleteFileByIdMutation();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const gooseContext = useGooseContext();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [listObjects, { isLoading: isObjectsLoading, isError: isObjectLoadingError }] =
        useLazyListObjectsQuery();

    const artifactObjects = useAppSelector(selectBaseArtifactsObjects);

    useEffect(() => {
        if (isObjectsLoading) {
            setIsLoading(true);
            setIsError(false);
        } else if (isObjectLoadingError) {
            setIsLoading(false);
            setIsError(true);
        }
    }, [isObjectsLoading, isObjectLoadingError]);

    const handleUploadFile = async (file: File) => {
        const response = await UpdateFileTrigger({
            organisationId: orgUuid || '',
            workspaceId: workspaceUuid || '',
            filePath: `${file.name}`,
            uploadFile: file,
        });
        return response;
    };

    const handleDeleteFile = async (file_id: string) => {
        const response = await DeleteFileTrigger({
            organisationId: orgUuid || '',
            workspaceId: workspaceUuid || '',
            fileId: file_id,
        });
        return response;
    };

    const handleError = async (error: unknown, file: File) => {
        addMessage({
            message: `Failed to upload file ${file.name}.`,
            type: NotificationType.ERROR,
        });
        trackError(`Error: ${error} uploading file "${file.name}"`);
        return Promise.reject(error);
    };

    async function fetchObjects(fileData?: DownloadFileResponse) {
        if (!projectName || projectName === BASE_MODEL) {
            const { data: objectList } = await listObjects({
                orgId: orgUuid || '',
                workspaceId: workspaceUuid || '',
            });
            const filteredNewProject = objectList?.objects
                .filter((object) => !object.path.includes(`/_${PROJECT_EXTENSION}/`))
                .map((object) => {
                    // Note that this is a kludge for now. If the object defines a color we could use that.
                    // Otherwise, perhaps we should use a single default color and force the user to assign
                    // a color. Or use the color stored in the object data itself (but that's a whole other
                    // story...)
                    const initialColor = initialColorGenerator();
                    return {
                        type: GtmEvoFileType.GeoscienceObject,
                        id: object.object_id,
                        name: object.name,
                        version_id: object.version_id,
                        schema: object.schema,
                        color: rgbArrayToGtmColor(initialColor),
                    };
                });
            dispatch(clearCurrentProject({}));
            if (filteredNewProject) {
                dispatch(setBaseArtifacts({ objects: filteredNewProject }));
                return filteredNewProject;
            }
            return null;
        }

        if (!fileData) {
            setIsLoading(false);
            setIsError(true);
            return null;
        }
        const projectJson = (await fetch(fileData.download).then((response) =>
            response.json(),
        )) as GtmProject;
        if (projectJson) {
            dispatch(setCurrentProject({ project: projectJson, selectedAnalyticalModelIndex: 0 }));
            dispatch(setCurrentProjectVersionId(fileData.version_id));
            setIsLoading(false);
            setIsError(false);
            return projectJson.analytical_models[0].objects;
        }

        return null;
    }

    async function uploadProject(project: GtmProject, name: string) {
        dispatch(setCurrentProject({ project }));

        const updatedFile = new File(
            [
                new Blob([JSON.stringify(project)], {
                    type: 'application/json',
                }),
            ],
            name,
        );
        try {
            const fileData = await UpdateFileTrigger({
                organisationId: orgUuid || '',
                workspaceId: workspaceUuid || '',
                filePath: name,
                uploadFile: updatedFile,
            }).unwrap();
            dispatch(setCurrentProjectVersionId(fileData.version_id));
        } catch (error) {
            addMessage({
                message: ERROR_UPLOADING_PROJECT,
                type: NotificationType.ERROR,
            });
            trackError(`Error: ${error} uploading new version of project "${name}"`);
        }
    }

    async function addBaseArtifact(file: File) {
        // Upload the file to the File service temporarily. The compute task will fetch this file by
        // id and transform it into a triangle-mesh object.
        const uploadResponse = await handleUploadFile(file);
        if ('error' in uploadResponse) {
            return handleError(uploadResponse.error, file);
        }

        const stem = fileNameExtensionRemover(file.name);

        const { data: result, isError: isTransformationError } = await GtmMeshTransformationTrigger(
            formGtmMeshTransformationBody(
                gooseContext!,
                GtmMeshTransformationAction.UploadMeshData,
                [],
                {
                    file_id: uploadResponse.data.file_id,
                    name: stem,
                },
            ),
        );

        const deleteResponse = await handleDeleteFile(uploadResponse.data.file_id);
        if ('error' in deleteResponse) {
            return handleError(deleteResponse.error, file);
        }

        if (isTransformationError || result?.created.length !== 1 || !result?.created[0]) {
            return handleError(new Error('Error creating triangle-mesh object'), file);
        }

        // We expect exactly one triangle-mesh created from an obj file.
        const { id, version } = result.created[0];

        const initialColor = initialColorGenerator();
        const newObject: GtmEvoOutputObject = {
            type: GtmEvoFileType.GeoscienceObject,
            id,
            name: stem,
            version_id: version,
            // TODO: Get schema dynamically, probably returning it from the compute API in the
            // `GtmObject` type
            schema: MESH_SCHEMA,
            color: rgbArrayToGtmColor(initialColor),
        };

        let newIndex = 0;
        for (const [key, value] of Object.entries(artifactObjects)) {
            if (value.id === id) {
                newIndex = parseInt(key, 10);
                break;
            }
            newIndex += 1;
        }

        dispatch(setBaseArtifacts({ objects: { ...artifactObjects, [newIndex]: newObject } }));
        return newObject;
    }

    return { isLoading, isError, fetchObjects, uploadProject, addBaseArtifact };
}
