export enum TransformationParam {
    needleThresholdRatio = 'needleThresholdRatio',
    needleCollapseLength = 'needleCollapseLength',
    capMinimumAngleInRadians = 'capMinimumAngleInRadians',
}

export function setTransformationParam(key: string, value: number) {
    window.localStorage.setItem(key, value.toString());
}

export function getTransformationParam(key: string): number | null {
    const value = window.localStorage.getItem(key);
    if (value) {
        const numberValue = value !== null ? Number(value) : null;
        return numberValue;
    }
    return null;
}
