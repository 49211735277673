import { getCurrentEvoInstance } from '@local/login';
import { getCombinedToken as getAccessToken } from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
    TASK_TOPIC,
    TASK_ROOT_TASK,
    TASK_DEV_BASE_URL,
    TASK_POLLING_TIMEOUT_MS,
    TASK_POLLING_INTERVAL_BASE_MS,
    TASK_POLLING_INTERVAL_MULT,
    TASK_POLLING_INTERVAL_EXP,
    ENVIRONMENT,
    ENVIRONMENT_DEVELOPMENT,
} from 'src/constants';

import { waitForMs } from '../file/utils';
import {
    GtmMeshGetMeshDataAction,
    GtmMeshData,
    GtmGooseContext,
    GtmMeshDataRequestBody,
    GtmMeshDetectorRequestBody,
    GtmMeshDetectionData,
    GtmMeshTransformationRequestBody,
    GtmMeshTransformationResponse,
    GtmObjects,
    GtmBaseRequestBody,
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
    GtmMeshDetectorParams,
    GtmMeshTransformationParams,
    GtmMeshTransformationApiResponse,
    JobStatus,
    TaskStatusResponse,
} from './gtmComputeApi.types';

interface TaskResult {
    status: JobStatus;
    error: string | null;
    results: any;
}

const apiHeaders = () => {
    const token = getAccessToken()?.access_token;
    return {
        'Content-Type': 'application/json',
        'API-Preview': 'opt-in',
        Authorization: `Bearer ${token}`,
    };
};

const pollTaskCompletion = async (url: string) => {
    /* eslint-disable no-await-in-loop */
    const statusUrl = `${url}/status`;
    const startTime = Date.now();
    while (Date.now() - startTime < TASK_POLLING_TIMEOUT_MS) {
        const waitTime =
            TASK_POLLING_INTERVAL_BASE_MS +
            (TASK_POLLING_INTERVAL_MULT * (Date.now() - startTime)) ** TASK_POLLING_INTERVAL_EXP;
        await waitForMs(waitTime);

        const response = await fetch(statusUrl, {
            method: 'GET',
            headers: apiHeaders(),
        });
        if (response.status !== 202) {
            return { error: await response.json() };
        }
        const taskInitResult: TaskStatusResponse = await response.json();

        if (
            taskInitResult.job_status.status !== JobStatus.InProgress &&
            taskInitResult.job_status.status !== JobStatus.Cancelling &&
            taskInitResult.job_status.status !== JobStatus.Requested
        ) {
            return taskInitResult;
        }
    }
    /* eslint-enable no-await-in-loop */

    return {
        job_status: { status: JobStatus.Failed, error: 'Task timed out' },
    };
};

interface GtmTaskBody<T> {
    parameters: T;
}

function taskBaseUrl() {
    const evoHub = getCurrentEvoInstance()?.hub?.url;
    if (ENVIRONMENT === ENVIRONMENT_DEVELOPMENT || !evoHub) {
        return TASK_DEV_BASE_URL;
    }
    // Currently the host is in the old URL pattern (eg 351mt.api.integration.seequent.com) but the compute API require the new pattern (eg 351mt.seq-int-apis.com), so convert
    return `${evoHub.split('.')[0]}.seq-int-apis.com`;
}

const computeAsyncTask = async (body: GtmTaskBody<GtmBaseRequestBody>, taskName: string) => {
    const baseUrl = taskBaseUrl();
    const taskInitUrl = `${baseUrl}/compute/orgs/${body.parameters.gooseContext.orgId}/${TASK_TOPIC}/${taskName}/`;

    // Request the async task start
    const taskInitResultReq = await fetch(taskInitUrl, {
        method: 'POST',
        headers: apiHeaders(),
        body: JSON.stringify(body),
    });
    if (taskInitResultReq.status !== 202 && taskInitResultReq.status !== 200) {
        return { error: await taskInitResultReq.json() };
    }
    const taskInitResult: TaskStatusResponse = await taskInitResultReq.json();

    const taskUrl = `${baseUrl}${taskInitResult.links.cancel || taskInitResult.links.result}`;
    await pollTaskCompletion(taskUrl);

    // Get the task result
    const res = await fetch(taskUrl, {
        method: 'GET',
        headers: apiHeaders(),
    });
    if (res.status !== 200) {
        return { error: await res.json() };
    }
    const taskResult: TaskResult = await res.json();
    if (taskResult.error) {
        return { error: taskResult.error };
    }
    return { data: taskResult.results };
};

export const gtmComputeApi = createApi({
    reducerPath: 'gtmTaskApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }), // unused when we're using queryFn
    endpoints: (builder) => ({
        gtmMeshData: builder.query<GtmMeshData, GtmTaskBody<GtmMeshDataRequestBody>>({
            queryFn: async (body) => {
                const res = await computeAsyncTask(body, TASK_ROOT_TASK);
                if (res.error) return res;
                const taskResult = res.data;
                return {
                    data: {
                        vertices: new Float32Array(JSON.parse(taskResult.points).flat()),
                        triangles: new Int32Array(JSON.parse(taskResult.indices).flat()),
                    },
                };
            },
        }),

        gtmMeshDetector: builder.query<
            GtmMeshDetectionData,
            GtmTaskBody<GtmMeshDetectorRequestBody>
        >({
            queryFn: async (body) => {
                const res = await computeAsyncTask(body, TASK_ROOT_TASK);
                if (res.error) return res;

                const taskResult = res.data;
                const arr: string[] = taskResult ? Object.values(taskResult) : [];
                const parsedArr = arr.map((str) => JSON.parse(str));
                return { data: parsedArr };
            },
        }),

        gtmMeshTransformation: builder.query<
            GtmMeshTransformationResponse,
            GtmTaskBody<GtmMeshTransformationRequestBody>
        >({
            queryFn: async (body) => {
                const res = await computeAsyncTask(body, TASK_ROOT_TASK);
                if (res.error) {
                    // TODO: figure out how to return this in a way that callers can get the error info
                    return res;
                }

                const taskResult: GtmMeshTransformationApiResponse = res.data;
                if (taskResult) {
                    const parsedObject = Object.entries(taskResult).reduce((acc, [key, value]) => {
                        const object = JSON.parse(value);
                        return {
                            ...acc,
                            [key]: object,
                        };
                    }, {});
                    return { data: parsedObject };
                }
                return { data: {} };
            },
        }),
    }),
});

export const {
    useGtmMeshDataQuery,
    useLazyGtmMeshDataQuery,
    useGtmMeshDetectorQuery,
    useLazyGtmMeshDetectorQuery,
    useGtmMeshTransformationQuery,
    useLazyGtmMeshTransformationQuery,
} = gtmComputeApi;

export const formGtmMeshDataRequestBody = (
    gooseContext: GtmGooseContext,
    objects: GtmObjects,
): GtmTaskBody<GtmMeshDataRequestBody> => ({
    parameters: {
        gooseContext,
        action: GtmMeshGetMeshDataAction.GetMeshData,
        objects,
        params: {},
    },
});

export const formGtmMeshDetectorBody = (
    gooseContext: GtmGooseContext,
    detectorAction: GtmMeshDetectorAction,
    objects: GtmObjects,
    params: GtmMeshDetectorParams,
): GtmTaskBody<GtmMeshDetectorRequestBody> => ({
    parameters: {
        gooseContext,
        action: detectorAction,
        objects,
        params,
    },
});

export const formGtmMeshTransformationBody = (
    gooseContext: GtmGooseContext,
    transformationAction: GtmMeshTransformationAction,
    objects: GtmObjects,
    params: GtmMeshTransformationParams,
): GtmTaskBody<GtmMeshTransformationRequestBody> => ({
    parameters: {
        gooseContext,
        action: transformationAction,
        objects,
        params,
    },
});
