export const X_LABEL = 'X';
export const Y_LABEL = 'Y';
export const Z_LABEL = 'Z';
export const BOUNDING_BOX_TITLE = 'New Boundary';
export const NAME_TITLE = 'Name';
export const BOUNDING_BOX_DEFAULT_NAME = BOUNDING_BOX_TITLE;
export const MIN_TITLE = 'Minimum';
export const MAX_TITLE = 'Maximum';
export const NO_SELECTION_TEXT = 'Select a point on the model to create a bounding box.';
export const ACCEPT = 'Create Boundary';
