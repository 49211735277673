import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { createSnapshotToUpdateColorAndBackColor } from '@local/webviz/dist/context/snapshots';
import { Color as XyzColor } from '@local/webviz/dist/types/xyz';
import { useEffect, useState } from 'react';

import {
    formGtmMeshDataRequestBody,
    useLazyGtmMeshDataQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { clearDefects } from 'src/store/project/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import {
    addOrUpdateSceneObject,
    clearSceneObjects,
} from 'src/store/visualization/visualizationSlice';
import {
    getMeshSnapshot,
    getTiledMeshSnapshot,
    updateElementSnapshot,
} from 'src/visualization/context/generateData';

import { buildGooseContext, geoscienceObjectByVersion } from './utils';

export function useSceneObjectDataManager() {
    const dispatch = useAppDispatch();
    const sceneObjects = useAppSelector(sceneObjectMap);
    const [GtmMeshDataTrigger] = useLazyGtmMeshDataQuery();
    const gooseContext = buildGooseContext();

    const {
        useXyzListener,
        addViewStatusListener,
        setStateFromSnapshot,
        removeViewsFromPlotDirectly,
        addViewToPlotDirectly,
        getState,
    } = useBaseXyz();

    async function loadGtmTilesetObject(objectId: string, versionId: string, name: string = '') {
        if (sceneObjects[objectId]?.versionId === versionId) return Promise.resolve();

        dispatch(
            addOrUpdateSceneObject([objectId, { isLoading: true, name, objectId, versionId }]),
        );

        const snapshot = getTiledMeshSnapshot(gooseContext!, objectId, versionId);

        const setStatePromise = setStateFromSnapshot(snapshot, {});

        addViewToPlotDirectly(`${objectId}`);
        return setStatePromise;
    }

    async function loadGtmObject(objectId: string, versionId: string, name: string = '') {
        if (
            sceneObjects[objectId]?.versionId === versionId &&
            !isObjectOnPlotByObjectId(objectId)
        ) {
            addViewToPlotDirectly(`${objectId}`);
        } else {
            const elementId = `${objectId}-element`;
            setStateFromSnapshot(getMeshSnapshot(elementId, `${objectId}`), {});
            addViewToPlotDirectly(`${objectId}`);

            dispatch(
                addOrUpdateSceneObject([objectId, { isLoading: true, name, objectId, versionId }]),
            );

            const {
                data: meshData,
                isSuccess: meshDataSuccess,
                isLoading: meshDataLoading,
            } = await GtmMeshDataTrigger(
                formGtmMeshDataRequestBody(
                    gooseContext!,
                    geoscienceObjectByVersion(objectId, versionId),
                ),
            );

            if (meshDataSuccess) {
                dispatch(
                    addOrUpdateSceneObject([
                        objectId,
                        {
                            isLoading: meshDataLoading,
                            isSuccess: meshDataSuccess,
                        },
                    ]),
                );
                setStateFromSnapshot(updateElementSnapshot(elementId, meshData), {});
            }
        }
    }

    function updateObjectColor(objectId: string, color: XyzColor) {
        const colorUpdateSnapshot = createSnapshotToUpdateColorAndBackColor(
            `${objectId}`,
            color,
            color,
        );
        setStateFromSnapshot(colorUpdateSnapshot, {});
    }

    function removeDefectsByObjectId(objectId: string) {
        const defectedObjects =
            Object.keys(getState()).filter((key) => key.includes(objectId)) ?? [];
        removeViewsFromPlotDirectly([...defectedObjects]);
    }

    function removeGtmObject(objectId: string) {
        removeViewsFromPlotDirectly([objectId]);
        removeDefectsByObjectId(objectId);
    }

    function clearGtmObjects() {
        setStateFromSnapshot(
            { plot: { views: [] }, selector: { active: false, primitiveId: -1 } },
            {},
        );
        dispatch(clearSceneObjects({}));
        dispatch(clearDefects({}));
    }

    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    function useViewListener(listItemId: string) {
        const [isViewLoading, setIsViewLoading] = useState(false);
        const [isViewError, setIsViewError] = useState(false);

        useEffect(() => {
            const objectViewId = getViewIdOnPlotByObjectId(listItemId);
            if (!objectViewId) return () => {};
            const removeViewStatusListener = addViewStatusListener({
                viewId: objectViewId,
                onComplete: () => {
                    setIsViewLoading(false);
                    setIsViewError(false);
                    dispatch(
                        addOrUpdateSceneObject([
                            objectViewId,
                            {
                                isLoading: false,
                            },
                        ]),
                    );
                },
                onError: (_failedKey: string) => {
                    setIsViewLoading(false);
                    setIsViewError(true);
                    dispatch(
                        addOrUpdateSceneObject([
                            objectViewId,
                            {
                                isLoading: false,
                                isError: true,
                            },
                        ]),
                    );
                },
                onPending: () => {
                    setIsViewLoading(true);
                    setIsViewError(false);
                    dispatch(
                        addOrUpdateSceneObject([
                            objectViewId,
                            {
                                isLoading: true,
                            },
                        ]),
                    );
                },
            });
            return () => {
                removeViewStatusListener();
            };
        }, [views]);

        return {
            isViewLoading,
            isViewError,
        };
    }

    function isObjectOnPlotByObjectId(objectId: string) {
        return views.some((view: string) => view.includes(objectId));
    }
    function getViewIdOnPlotByObjectId(objectId: string) {
        return views.find((view: string) => view.includes(objectId));
    }

    return {
        loadGtmObject,
        removeGtmObject,
        isObjectOnPlotByObjectId,
        useViewListener,
        clearGtmObjects,
        updateObjectColor,
        loadGtmTilesetObject,
    };
}
