import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

import { GtmState } from './gtmSlice.types';
import { initialGtmState } from './selectors';

export const gtmSlice: Slice = createSlice({
    name: 'gtm',
    initialState: initialGtmState,
    reducers: {
        setWorkspaceName(gtmState: GtmState, { payload }: PayloadAction<string>) {
            return {
                ...gtmState,
                workspace: { ...gtmState.workspace, name: payload },
            };
        },
    },
});

export const { setWorkspaceName } = gtmSlice.actions;
