import { getConfig } from '@local/app-config';
import { ContentArea } from '@local/content-area';
import { useGetUserDetailsQuery } from '@local/login';
import { getCurrentUserDetails } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { Messages } from '@local/messages/dist/Messages';
import { NavDrawerItem } from '@local/web-design-system/dist/components/NavDrawer';
import { useTrace } from '@local/web-design-system/dist/utils';
import classnames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import { LogoTitle } from '../../assets/logoTitle';
import { GtmBreadcrumbs } from '../GtmBreadcrumbs';
import { useStyles } from './ContentScaffolding.styles';

export const ContentScaffolding = () => {
    const { classes } = useStyles();
    const location = useLocation();
    const applyTrace = useTrace('gtm-content-div');

    const user = (() => {
        if (getConfig().bentleyIdEnabled) {
            return getCurrentUserDetails();
        }
        return useGetUserDetailsQuery();
    })();

    const navItems: NavDrawerItem[] = [
        {
            key: 'modeler',
            text: 'Modeler',
            location: '/',
            icon: 'evo',
        },
    ];
    const breadcrumbComponent = <GtmBreadcrumbs />;

    return (
        <ContentArea
            currentRoute={location.pathname}
            navItems={navItems}
            service="geotechnical-modeler"
            variant="dark"
            userResult={user}
            exactMatch={false}
            logoOnly={<img src={logo} className={classes.appLogo} alt="Modeler" />}
            logoWithTitle={<LogoTitle />}
            evoSelector={breadcrumbComponent}
        >
            <div className={classnames(classes.appContent, classes.webVizContent)} {...applyTrace}>
                <Outlet />
            </div>
            <Messages />
        </ContentArea>
    );
};
