import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { PersistentTextField } from '@local/web-design-system/dist/components/PersistentTextField';
import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import ElementsIcon from '@local/web-design-system/dist/icons/Menu/ElementsIcon';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AGGREGATE_GEOMETRY_NAME } from 'src/constants';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { selectedWorkspaceName } from 'src/store/gtm/selectors';
import { useAppSelector } from 'src/store/store';
import { sceneObjectByName } from 'src/store/visualization/selectors';

import {
    APPROXIMATE_PARAM_TIME,
    PARAM_BUTTON_LABEL,
    PARAM_ERROR_MESSAGE,
    PARAM_MESSAGE,
    PARAM_OBJECT_NAME,
    PARAM_OBJECT_TEXT_INPUT_LABEL,
    PARAM_SUCCESSFUL_MESSAGE,
    PARAM_TITLE,
    WORKSPACE_TEXT_INPUT_LABEL,
} from './AggregateDialog.constants';
import { useStyles } from './AggregateDialog.styles';

export function AggregateDialog() {
    const { classes } = useStyles();
    const [showParamDialog, setShowParamDialog] = useState(false);
    const workspaceName = useAppSelector(selectedWorkspaceName);
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isParamError, setIsParamError] = useState(false);
    const [isParametrizationRunning, setIsParametrizationRunning] = useState(false);
    const gooseContext = useGooseContext();

    const aggregateGeometryObject = useAppSelector(sceneObjectByName(AGGREGATE_GEOMETRY_NAME));

    async function createParametricModel() {
        if (aggregateGeometryObject && gooseContext) {
            setIsParametrizationRunning(true);
            const { objectId, versionId } = aggregateGeometryObject;
            const {
                data: result,
                isError,
                isLoading,
            } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(
                    gooseContext!,
                    GtmMeshTransformationAction.ParameterizeVolumes,
                    [{ id: objectId, version: versionId }],
                    {},
                ),
            );

            setIsParametrizationRunning(isLoading);
            if (!isError && result?.created?.length) {
                setIsSuccess(true);
                return result.created.map(({ id, version }) => ({
                    id,
                    version_id: version,
                }));
            }
            setIsParamError(isError);
        }
        return Promise.reject(new Error(PARAM_ERROR_MESSAGE));
    }

    return (
        <>
            <Grid
                container
                item
                xs
                direction="column"
                wrap="nowrap"
                width="100%"
                alignItems="baseline"
                className={classes.root}
            >
                <Divider />
                <Grid container item xs direction="row" width="100%">
                    <ElementsIcon className={classes.paramIcon} />
                    <Typography className={classes.titleFont}>{PARAM_TITLE}</Typography>
                </Grid>
                <Typography className={classes.messageFont}>{PARAM_MESSAGE}</Typography>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setShowParamDialog(true)}
                        >
                            {PARAM_BUTTON_LABEL}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                dialogTitle="Creating Parametric model"
                confirmText={isParametrizationRunning || isSuccess ? '' : 'Create'}
                cancelText={isSuccess ? '' : 'Cancel'}
                displayCloseBtn
                open={showParamDialog}
                icon={<ElementsIcon />}
                action={(isVisible) => {
                    if (isVisible !== null) {
                        setShowParamDialog(isVisible);
                    }
                    createParametricModel();
                }}
            >
                <Grid
                    container
                    item
                    xs
                    direction="row"
                    flexWrap="nowrap"
                    width="100%"
                    className={classes.paramDialogRoot}
                >
                    <Box className={classes.boxWidth}>
                        <Typography
                            id="name-input-label"
                            variant="body2"
                            className={classes.paramButtonText}
                        >
                            {PARAM_OBJECT_TEXT_INPUT_LABEL}
                        </Typography>
                    </Box>

                    <PersistentTextField defaultValue={PARAM_OBJECT_NAME} disabled />
                </Grid>
                <Grid
                    container
                    item
                    xs
                    direction="row"
                    flexWrap="nowrap"
                    width="100%"
                    className={classes.paramDialogRoot}
                >
                    <Box className={classes.boxWidth}>
                        <Typography
                            id="workspace-input-label"
                            variant="body2"
                            className={classes.paramButtonText}
                        >
                            {WORKSPACE_TEXT_INPUT_LABEL}
                        </Typography>
                    </Box>

                    <PersistentTextField defaultValue={workspaceName} disabled />
                </Grid>
                {isParametrizationRunning && (
                    <LinearProgressFaked
                        isLoading={isParametrizationRunning}
                        milliseconds={APPROXIMATE_PARAM_TIME}
                        hideWhenNotLoading
                        classes={{ root: classes.loadingProgressContainer }}
                    />
                )}
                {isSuccess && (
                    <Typography
                        id="workspace-input-label"
                        variant="body2"
                        className={classes.successfulText}
                    >
                        {PARAM_SUCCESSFUL_MESSAGE}
                    </Typography>
                )}
                {isParamError && (
                    <Typography
                        id="workspace-input-label"
                        variant="body2"
                        className={classes.errorText}
                    >
                        {PARAM_ERROR_MESSAGE}
                    </Typography>
                )}
            </Dialog>
        </>
    );
}
